import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/SEO/SEO"

import Hero from "../sections/article/Hero/Hero"
import Body from "../sections/article/Body/Body"
import Reviews from "../sections/common/Reviews/Reviews"

const Article = ({ data }) => {
  if (!data) return null

  const postData = data.prismicBlog.data

  return (
    <>
      <SEO
        title={postData.meta_title.text}
        description={postData.meta_description.text}
      />

      <section>
        <Hero postData={postData} />
      </section>

      <section>
        <Body postData={postData} />
      </section>

      <section className="pb-5 md:pb-7 lg:pb-10">
        <Reviews />
      </section>
    </>
  )
}
export default Article

export const query = graphql`
  query ($slug: String!) {
    prismicBlog(uid: { eq: $slug }) {
      data {
        blog_title {
          text
        }
        content {
          html
        }
        date(formatString: "MMMM DD, YYYY")
        image {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }

        meta_title {
          text
        }
        meta_description {
          text
        }
      }
    }
  }
`

import React from "react"

import Container from "../../../components/Container/Container"
import Title from "../../../components/Title/Title"
import PageCover from "../../../components/PageCover/PageCover"

const Hero = ({ postData }) => {
  return (
    <>
      <div className="relative">
        <PageCover
          imageLocalFile={postData.image}
          alt={postData.blog_title.text}
        />
        <div className="absolute inset-0 z-20 bg-black bg-opacity-50">
          <div className="flex flex-col items-center justify-center w-full h-full text-center">
            <Container className="">
              <div className="mb-4">
                <Title colorClass="text-white" singlePage>
                  {postData.blog_title.text}
                </Title>
              </div>

              <div>
                <div className="text-sm text-white md:text-base text-opacity-90">
                  {postData.date}
                </div>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  )
}

export default Hero

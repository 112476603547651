import React from "react"

import Container from "../../../components/Container/Container"
import * as styles from "./Body.module.css"

const Body = ({ postData }) => {
  return (
    <>
      <Container>
        <div className={`flex justify-center mx-auto prose  ${styles.article}`}>
          <div dangerouslySetInnerHTML={{ __html: postData.content.html }} />
        </div>
      </Container>
    </>
  )
}

export default Body
